import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "shared/config/routes";

import { RequireAuthProps } from "./interfaces";
import { LoadingIcon } from "./styled";

const RequireAuth = ({
  loggedIn,
  isActiveUserLoaded,
  render,
}: RequireAuthProps) => {
  const history = useHistory();
  const showSpinner = !isActiveUserLoaded || !loggedIn;
  const currentRoute = history.location.pathname;

  useEffect(() => {
    if (!loggedIn && currentRoute !== ROUTES.login) {
      window.location.href = `${window.location.origin}${ROUTES.login}?r=${currentRoute}`;
    }
  }, [loggedIn]);

  return showSpinner ? <LoadingIcon data-testid="loading-spinner" /> : render();
};

export default RequireAuth;
