import moment from "moment";

import { FeeType } from "shared/interfaces/types";

import { LEASE_STATUS_CODES } from "./appTyped.constants";

export enum PROVIDER {
  YARDI = "yardi",
  ENTRATA = "entrata",
  REAL_PAGE = "real-page",
}

export enum BACKGROUND_REPORT_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  UNAVAILABLE = "unavailable",
}

export const VANTAGE_SCORE_EXCLUSION_REASONS = {
  DECEASED: 'Deceased',
  LACK_OF_INFORMATION: 'Lack of information',
  TOO_MANY_RECORDS: 'Too many records',
}

export const INCOME_STATUSES = {
  active: "Active",
  inactive: "Inactive",
};

export const INCOME_VERIFICATION_TYPES = {
  bankAccount: "Bank Account",
  employment: "Employment",
  benefits: "Benefits",
  tenNinetyNine: "1099",
};

export const INCOME_ASSET_STATUS_CODES = {
  Pending: 1,
  Complete: 2,
  Confirmed: 3,
  Skipped: 4,
};

export enum DEAL_UI_STATUS_CODES {
  invitesSent = "IS",
  applicationsIncomplete = "AI",
  partiallySubmitted = "APS",
  submitted = "AS",
  approved = "DAP",
  condApproved = "DCA",
  listingClosed = "LC",
  declined = "DCL",
  cancelled = "CNC",
  approvedInYardi = "YAP",
  declinedInYardi = "YDL",
  cancelledInYardi = "YCN",
  sentToEntrata = "STP",
}

export enum APPLICANT_SOURCES {
  PMS = "pms",
  VERO = "vero",
}

export const DEAL_UI_STATUS_LABEL = Object.freeze({
  [DEAL_UI_STATUS_CODES.invitesSent]: "Invites Sent",
  [DEAL_UI_STATUS_CODES.applicationsIncomplete]: "Applications in Progress",
  [DEAL_UI_STATUS_CODES.partiallySubmitted]: (
    numCompleteApplications,
    numTotalApplications
  ) =>
    `${numCompleteApplications} of ${numTotalApplications} applications submitted`,

  [DEAL_UI_STATUS_CODES.submitted]: "Application Awaiting Decision",
  [DEAL_UI_STATUS_CODES.condApproved]: "Requested More Info",
  [DEAL_UI_STATUS_CODES.approved]: "Application Approved",
  [DEAL_UI_STATUS_CODES.approvedInYardi]: "Application Approved",
  [DEAL_UI_STATUS_CODES.cancelled]: "Application Cancelled",
  [DEAL_UI_STATUS_CODES.cancelledInYardi]: "Application Cancelled",
  [DEAL_UI_STATUS_CODES.declined]: "Application Declined",
  [DEAL_UI_STATUS_CODES.declinedInYardi]: "Application Declined",
  [DEAL_UI_STATUS_CODES.listingClosed]: "Listing Closed",
});

export const TU_STATUSES = {
  reportReady: "ReportsAvailable",
  notStarted: "NoActivity",
  requestAccepted: "RequestAccepted",
  generating: "ReportsRequested",
  IDMACompleted: "IDMACompleted",
  pass: "Pass",
  fail: "Fail",
  additionalQuestions: "AdditionalQuestions",
};

export const APPS = {
  admin: "admin",
  manager: "manager",
  renter: "renter",
  leads: "leads",
  surveys: "surveys",
} as const;

export const NOTIFICATIONS = {
  info: "info",
  warning: "warning",
  error: "error",
};

export const LOCAL_STORAGE = {
  jwtToken: "jwtToken",
  activeCompany: "activeCompany",
  companies: "companies",
  logout: "logout",
  notAskLeaseDownload: "notAskLeaseDownload",
  dealOverviewVersion: "dealOverviewVersion",
};

export const REGEX = {
  phoneNumber: /\d{10,15}/,
  name: /^[A-Za-z ']+$/, // letters, spaces and apostrophes,
  oneLetterOneNumber: /(?=.*[0-9])(?=.*[a-zA-Z])/,
  lettersNumbersSpecialChars: /^[A-Za-z0-9 \-.]+$/, // letters, numbers, space, dash, dot,
  zipcode: /^[0-9]{5}$/,
  ssn: /^(?!(000|666|9))\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0000)\d{4}$/,
  ssnChar: /^[\d\- ]*$/,
  otpCode: /^[0-9]{6}$/,
};

export enum INPUT_SIZES {
  default = "default",
  lg = "large",
}

export const HTTP_ERROR_CODES = {
  badRequest: 400,
  unauthorized: 401,
  paymentRequired: 402,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  preconditionFailed: 412,
};

export const HTTP_METHOD = Object.freeze({
  post: "POST",
  get: "GET",
  patch: "PATCH",
  delete: "DELETE",
  put: "PUT",
});

export const CONTENT_TYPE = {
  deal: 30,
  dealSetup: "vero.dealsetup",
  property: "vero.property",
};

export enum APPLICATION_STATUSES {
  notStarted = 1,
  inProgress = 2,
  submitted = 3,
  approved = 4,
  declined = 5,
  dealCancelled = 6,
  listingClosed = 7,
  notRequired = 8,
}

export const APPLICATION_STATUS_LABEL = Object.freeze({
  [APPLICATION_STATUSES.notStarted]: "Not Started",
  [APPLICATION_STATUSES.inProgress]: "In Progress",
  [APPLICATION_STATUSES.submitted]: "Submitted",
  [APPLICATION_STATUSES.approved]: "Approved",
  [APPLICATION_STATUSES.declined]: "Declined",
  [APPLICATION_STATUSES.dealCancelled]: "Deal Cancelled",
  [APPLICATION_STATUSES.listingClosed]: "Listing Closed",
  [APPLICATION_STATUSES.notRequired]: "Not Required",
});

export const APPLICANT_TYPES = {
  applicant: 1,
  guarantor: 2,
  occupant: 3,
};

export const EMPLOYMENT_STATUSES = {
  employed: "EM",
  employedNotStarted: "NS",
  unemployed: "NE",
};

export const DATE_FORMAT = "MMM. DD, YYYY";
export const DATE_TIME_FORMAT = "MMM. DD, YYYY hh:mm a";
export const FILE_NAME_DATE = "MMM-DD-YYYY";
export const MONTH_FORMAT = "MMM, YYYY";
export const YEAR_MONTH_DATE_FORMAT = "YYYY-MM-DD";
export const SHORT_DATE = "MM/DD/YY";

export const VERIFICATION_STATUSES = {
  VERIFIED: "Verified",
  UNVERIFIED: "Unverified",
  EDITED: "Edited",
};

export const US_PHONE_COUNTRY_CODE = "1";
export const US_PHONE_COUNTRY_MASK = "...-...-....";

export const SSN_LENGTH = 9;

export const DEFAULT_PAGINATION = {
  page: 1,
  pageSize: 10,
};

export const SORT_TYPES = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

export const QUERY_OPTIONS = {
  queries: {
    staleTime: 2.5 * 60 * 1000, // Refetch cached data in the background after 2.5 minutes in case the cache is active
    cacheTime: 500, // Min cache timeout to prevent looping
    refetchOnWindowFocus: false, // Avoid refetching after regaining focus
  },
};

const DASHBOARD_DEAL_DATE_FORMAT = "YYYY-MM-DD";
export const DASHBOARD_DEAL_OPTIONS = [
  {
    label: "All Applications",
    value: "all-applications",
  },
  {
    label: "Last day",
    value: moment().subtract(1, "day").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
  {
    label: "Last week",
    value: moment().subtract(1, "week").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
  {
    label: "Last month",
    value: moment().subtract(1, "month").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
  {
    label: "Last 3 months",
    value: moment().subtract(3, "month").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
  {
    label: "Last 6 months",
    value: moment().subtract(6, "month").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
  {
    label: "Last year",
    value: moment().subtract(1, "year").format(DASHBOARD_DEAL_DATE_FORMAT),
  },
];

export const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const PETS_GENDERS = {
  M: "Male",
  F: "Female",
};

export const FIELD_TYPES = {
  smart: "SM",
  manual: "MN",
  checkbox: "CB",
};

export const BLUEMOON_FIELD_TYPES = {
  enum: "enum",
  currency: "currency",
  date: "date",
  day: "day",
  decpercent: "decpercent",
  phone: "phone",
  textarea: "textarea",
  boolean: "boolean",
};

export const APPLICATION_ANSWER_TYPES = {
  industry: "industries",
  kid: "kidOptions",
  knobRider: "knobRiderOptions",
  windowGuard: "windowGuardOptions",
  marketingSource: "marketingSourceOptions",
};

export const HOLDING_DEPOSIT_STATUSES = Object.freeze({
  NO_HOLDING_DEPOSIT_TO_BE_PAID: "NO_HOLDING_DEPOSIT_TO_BE_PAID",
  WAITING_HOLDING_DEPOSIT_PAYMENT: "WAITING_HOLDING_DEPOSIT_PAYMENT",
  HOLDING_DEPOSIT_PAID: "HOLDING_DEPOSIT_PAID",
});

export const DEFAULT_LEASE_STATUS_ORDER = [
  LEASE_STATUS_CODES.applicantsSigned,
  LEASE_STATUS_CODES.inProgress,
  LEASE_STATUS_CODES.sent,
  LEASE_STATUS_CODES.partiallySigned,
  LEASE_STATUS_CODES.leaseHandledOffline,
  LEASE_STATUS_CODES.leaseExecutedOffline,
  LEASE_STATUS_CODES.managerSigned,
  LEASE_STATUS_CODES.processingManagerSignature,
];

export const LEASE_STATUS_LABEL = Object.freeze({
  [LEASE_STATUS_CODES.inProgress]: "Lease Creation In Progress",
  [LEASE_STATUS_CODES.sent]: "Signatures Pending",
  [LEASE_STATUS_CODES.partiallySigned]: (
    numSignedApplications,
    numTotalApplications
  ) =>
    `${numSignedApplications} of ${numTotalApplications} applicants signed lease`,

  [LEASE_STATUS_CODES.applicantsSigned]: "Countersignature Needed",
  [LEASE_STATUS_CODES.processingManagerSignature]: "Lease Is Being Executed",
  [LEASE_STATUS_CODES.managerSigned]: "Lease Fully Executed",
  [LEASE_STATUS_CODES.leaseHandledOffline]: "Lease Offline",
  [LEASE_STATUS_CODES.leaseExecutedOffline]: "Lease Signed Offline",
});

export const MAX_FILE_UPLOAD_SIZE = 5242880; // 5MB

export const MAX_FILE_UPLOAD_SIZE_1MB = 1048576; // 1MB

export const DOCUMENT_TYPES = {
  lease: "LS",
  renewalLease: "RL",
  rider: "RD",
};

export const LIVING_SITUATION_TYPES = Object.freeze({
  homeOwner: {
    key: "HO",
    labelPresent: "Applicant is an Owner",
    labelPast: "Applicant was an Owner",
    labelDropdownPresent: "I am a home owner",
    labelDropdownPast: "I was a home owner",
  },
  renter: {
    key: "RE",
    labelPresent: "Applicant is a Renter",
    labelPast: "Applicant was a Renter",
    labelDropdownPresent: "I am a renter",
    labelDropdownPast: "I was a renter",
  },
  campus: {
    key: "CA",
    labelPresent: "Applicant is living in campus",
    labelPast: "Applicant was living in campus",
    labelDropdownPresent: "I live on campus",
    labelDropdownPast: "I lived on campus",
  },
  familyOrFriends: {
    key: "FF",
    labelPresent: "Applicant is living with family/friends",
    labelPast: "Applicant was living with family/friends",
    labelDropdownPresent: "I live with family / friends",
    labelDropdownPast: "I lived with family / friends",
  },
  other: {
    key: "OT",
    labelPresent: "Other",
    labelPast: "Other",
    labelDropdownPresent: "Other",
    labelDropdownPast: "Other",
  },
});

export const LIVING_SITUATION_TYPE_LABELS = Object.values(
  LIVING_SITUATION_TYPES
).reduce(
  (prev, curr) => ({
    ...prev,
    [curr.key]: {
      labelPresent: curr.labelPresent,
      labelPast: curr.labelPast,
      labelDropdownPresent: curr.labelDropdownPresent,
      labelDropdownPast: curr.labelDropdownPast,
    },
  }),
  {}
);

export const DISCLAIMER_CODES = {
  applicantTermsOfService: "APPLICANT_TOS",
  applicantEqualHousingOpportunity: "APPLICANT_EHO",
  managerTermsOfService: "MANAGER_TOS",
};

export const BACKSPACE_KEY = "Backspace";

export const APP_VERO_URL = "https://app.sayvero.com/";

export const TERMS_OF_USE_URL = `${APP_VERO_URL}terms-of-use`;

export const SUBMITTED_VOR_SURVEY_URL = `${APP_VERO_URL}survey-thank-you`;

export const ANSWER_QUESTION_TYPES = {
  freeText: 1,
  singleChoice: 2,
};

export const PRIVACY_POLICY_URL = `${APP_VERO_URL}privacy-policy`;

export const COMMON_PENDING_ANIMATION_MESSAGES = [
  "Untangling wires...",
  "Connecting to dial-up...",
  "Beeeep, boop, beeep beep...",
  "Running errands...",
  "Eating lunch...",
  "Figuring out the length of Pi...",
];

export const VERIFICATION_TYPE = Object.freeze({
  ID: "ID",
  EMPLOYMENT: "EMPLOYMENT",
  CREDIT: "CREDIT",
  VOR: "VOR",
});

export enum VERIFICATION_TYPES {
  ID = "ID",
  EMPLOYMENT = "EMPLOYMENT",
  CREDIT = "CREDIT",
  VOR = "VOR",
}

export const VERIFICATION_TYPE_LABELS = Object.freeze({
  [VERIFICATION_TYPE.ID]: "ID Verification",
  [VERIFICATION_TYPE.EMPLOYMENT]: "Employment",
  [VERIFICATION_TYPE.CREDIT]: "Credit",
  [VERIFICATION_TYPE.VOR]: "Verification of Residency",
});

export const EXPORT_INVENTORY_KEY = "EXPORT_INVENTORY";

export const LEASE_MIN_DURATION_POLICY = Object.freeze({
  LIGHT_VALIDATION: "LIGHT_VALIDATION",
  STRONG_VALIDATION: "STRONG_VALIDATION",
});

export const COUNTY_CRIMINAL_SEARCH_STATUS = Object.freeze({
  NOT_ALLOWED: "NOT_ALLOWED",
  NOT_AVAILABLE: "NOT_AVAILABLE",
  NOT_REQUESTED: "NOT_REQUESTED",
  IN_PROGRESS: "IN_PROGRESS",
  PARTIALLY_REQUESTED: "PARTIALLY_REQUESTED",
  PARTIALLY_COMPLETED: "PARTIALLY_COMPLETED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
});

export enum COUNTY_CRIMINAL_SEARCH_STATUS_ENUM {
  NOT_ALLOWED = "NOT_ALLOWED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  NOT_REQUESTED = "NOT_REQUESTED",
  IN_PROGRESS = "IN_PROGRESS",
  PARTIALLY_REQUESTED = "PARTIALLY_REQUESTED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  NO_COUNTY_TO_RUN = "NO_COUNTY_TO_RUN",
}

export const APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS = Object.freeze({
  NOT_REQUESTED: "NOT_REQUESTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  NO_COUNTY_TO_RUN: "NO_COUNTY_TO_RUN",
});

export const ANCHOR_LINKS = Object.freeze({
  RENTER_PROFILE: Object.freeze({
    EMPLOYMENT_HISTORY: "employment-history",
  }),
});

export const OTHER = "OTHER";

export const DEAL_TYPES = Object.freeze({
  SCREENING: "SCREENING",
  PRESCREENING: "PRESCREENING",
});

export enum DealType {
  SCREENING = "SCREENING",
  PRESCREENING = "PRESCREENING",
}

export const VALIDATION_MESSAGES = Object.freeze({
  fieldRequired: "Field is required.",
  moveOutDate: "Move-out date can't be before move-in date.",
  endDate: "End date can't be before start date.",
  checkboxRequired: "Check the box to continue",
});

export const APPLICATION_STEPS = Object.freeze({
  selectTypeAndConfirm: "select_type_and_confirm_group",
  selectAccountType: "select_type",
  confirmGroup: "confirm_group",
  verifications: "verifications",
  identityVerification: "identity_verification",
  linkEmployment: "link_employment",
  renterProfile: "renter_profile",
  screening: "screening",
  incomeAsset: "income_and_asset",
  creditCheck: "background_credit_check",
  attachments: "attach_documents",
  additionalData: "conditional_request",
  // NOTE: this is a dedicated step for submitting the application. At the moment it's used only for the prescreening/leads
  // flow, for the regular flow the submit happens in the "attach_documents" (or the "conditional_request") steps.
  submitApplication: "submit_application",
  payment: "payment",
  initialization: "initialization",
  acceptTerms: "accept_terms",
});

export enum ApplicationStep {
  acceptInvitation = "accept_invitation",
  selectTypeAndConfirm = "select_type_and_confirm_group",
  selectAccountType = "select_type",
  confirmGroup = "confirm_group",
  verifications = "verifications",
  identityVerification = "identity_verification",
  linkEmployment = "link_employment",
  renterProfile = "renter_profile",
  screening = "screening",
  incomeAsset = "income_and_asset",
  creditCheck = "background_credit_check",
  attachments = "attach_documents",
  additionalData = "conditional_request",
  // NOTE= this is a dedicated step for submitting the application. At the moment it's used only for the prescreening/leads
  // flow, for the regular flow the submit happens in the "attach_documents" (or the "conditional_request") steps.
  submitApplication = "submit_application",
  payment = "payment",
  initialization = "initialization",
  acceptTerms = "accept_terms",
}

export const FEE_TYPE_LABELS = Object.freeze<{ [key in FeeType]: string }>({
  [FeeType.applicationFee]: "Application Fee",
  [FeeType.holdingDepositFee]: "Holding Deposit",
  [FeeType.stripeProcessingFee]: "Stripe Processing Fee",
});

export const UPLOAD_ALLOWED_FILE_TYPES = [".png", ".jpg", ".jpeg", ".pdf"];

export const MAX_INCOMES_NUMBER_IN_STREAM = 5;
