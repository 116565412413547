import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import has from "lodash/has";

import { API_URL, VEGA_BASE_URL } from "shared/config/apiRoutes";
import {
  HTTP_ERROR_CODES,
  HTTP_METHOD,
  NOTIFICATIONS,
} from "shared/config/constants";
import { TUError, ApiError, logError } from "shared/lib/errors";
import {
  cloneFailedResponseData,
  notifyHttpResponseSubscribers,
} from "shared/utils/api";
import {
  getCachedActiveCompanyId,
  logout,
  getAccessToken,
  getRefreshToken,
  setJwt,
} from "shared/utils/auth";
import { toSnakeCaseKeys, toCamelCaseKeys } from "shared/utils/misc.util";
import { clearStorageAndReload } from "shared/utils/sessionStorage";
import { openNotification } from "shared/utils/ui";

import authApi from "./authApi";

// TODO(V2-2973): Remove this export and replace all its usages with the const from `shared/config/apiRoutes`.
export { API_URL, VEGA_BASE_URL };

const getAuthHeader = (token) => `Bearer ${token}`;

const shouldSkipInterceptorErrorHandling = (error) => {
  return !!error?.config?.skipInterceptorErrorHandling;
};

const shouldIgnoreFailure = (error) => {
  return !!error?.config?.ignoreFailures;
};

const shouldClearStorageAndReloadOnBadRequest = (error) => {
  const status = error?.response?.status;
  return (
    !!error?.config?.clearStorageAndReloadOnBadRequest &&
    [HTTP_ERROR_CODES.notFound, HTTP_ERROR_CODES.badRequest].includes(status)
  );
};

export const setupApi = () => {
  // NOTE: By default the interceptor intercepts 401 responses - it's used for automatic auth token refresh
  createAuthRefreshInterceptor(
    axios,
    async (failedRequest) => {
      try {
        const refreshToken = getRefreshToken();

        // NOTE: If the refresh token is not present logout the user.
        if (!refreshToken) {
          throw new ApiError(HTTP_ERROR_CODES.unauthorized, [
            "Refresh token missing!",
          ]);
        }

        const newJwt = await authApi.requestNewToken(refreshToken);
        // NOTE: Set the new token.
        setJwt(newJwt);

        /* eslint-disable no-param-reassign */
        failedRequest.response.config.headers.Authorization = getAuthHeader(
          getAccessToken()
        );

        failedRequest.config.data = cloneFailedResponseData(
          failedRequest.config.data
        );
      } catch (error) {
        console.error(error)
        await logout();
      }
    },
    {
      // NOTE: Skipping the interceptor while we are attempting to refresh the token.
      skipWhileRefreshing: false,
    }
  );

  axios.interceptors.request.use((request) => {
    const updatedRequest = { ...request };

    if (request.method?.toUpperCase() === HTTP_METHOD.post) {
      updatedRequest.headers.Accept = "application/json";
      updatedRequest.headers["Content-Type"] = "application/json;charset=utf-8";
    }

    const jwt = getAccessToken();

    if (jwt) {
      // NOTE: Set the JWT auth token in the Authorization header `Bearer ${token}` for every request.
      updatedRequest.headers.Authorization = getAuthHeader(jwt);
    }

    const companyId = getCachedActiveCompanyId();
    if (!updatedRequest.headers.Company && companyId) {
      updatedRequest.headers.Company = companyId;
    }

    const isFormData = request.data instanceof FormData;

    if (
      request.data &&
      !isFormData &&
      (!has(request, "convertToSnakeCase") || request.convertToSnakeCase)
    ) {
      updatedRequest.data = toSnakeCaseKeys(request.data);
    }

    return updatedRequest;
  });

  axios.interceptors.response.use(
    (response) => {
      notifyHttpResponseSubscribers(response);

      let formattedData;
      if (response?.config?.returnRaw) {
        return response
      } else if (has(response.data, "detail")) {
        formattedData = response.data.detail;
      } else {
        formattedData = response.data && toCamelCaseKeys(response.data);
      }

      return {
        ...response,
        data: formattedData,
      };
    },
    (error) => {
      notifyHttpResponseSubscribers(error);

      if (shouldIgnoreFailure(error)) {
        return;
      }

      if (shouldSkipInterceptorErrorHandling(error)) {
        throw error;
      }

      if (shouldClearStorageAndReloadOnBadRequest(error)) {
        clearStorageAndReload();
        return;
      }

      let apiError;
      if (error.response && error.response.data.detail) {
        const { response } = error;
        apiError = new ApiError(response.status, {
          nonFieldErrors: [response.data.detail],
        });
      } else if (TUError.isTUError(error)) {
        apiError = new TUError(error);
      } else if (error.response && error.response.data) {
        const { response } = error;
        apiError = new ApiError(response.status, response.data);
      } else if (
        error.response &&
        error.response.status === HTTP_ERROR_CODES.notFound
      ) {
        const { response } = error;
        apiError = new ApiError(response.status);
      } else if (error.request) {
        apiError = new ApiError(ApiError.NETWORK_ERROR, {
          nonFieldErrors: [error.message],
        });
      } else if (error instanceof ApiError) {
        apiError = error;
      } else {
        apiError = new ApiError(ApiError.SETUP_ERROR, {
          nonFieldErrors: [error.message],
        });
        openNotification(
          "Sorry, something went wrong on our end and our team is investigating the issue. Please try again later.",
          NOTIFICATIONS.error
        );
        logError(error);
      }

      throw apiError;
    }
  );
};
